<template>
    <div>
        <b-row class="match-height">
            <b-col>
                <b-card>
                    <b-card-title>Estadística de imprevistos</b-card-title>
                    <vue-apex-charts
                        type="donut"
                        height="350"
                        :options="apexChatData.donutChart.chartOptions"
                        :series="apexChatData.donutChart.series"
                    />
                </b-card>
            </b-col>
            <b-col>
                <b-card>
                    <b-card-title>Motivos de cancelación</b-card-title>
                    <vue-apex-charts
                        type="bar"
                        height="350"
                        :options="apexChatData.barChart.chartOptions"
                        :series="apexChatData.barChart.series"
                    />
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-card>
                    <tabla-general></tabla-general>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import { ref, onMounted } from "@vue/composition-api";
import apexChatData from "@/components/apexChartData.js";
export default {
    setup(props) {
        return { apexChatData };
    },
};
</script>
